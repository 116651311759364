import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import { ThemeWrapper } from 'styles';
import LayoutMain from 'components/LayoutMain';
import { info } from 'sitedata';

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      keywords: string;
    };
  };
}

const IndexLayout: React.FC = ({ children, ...props }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `}
    render={(_data: StaticQueryProps) => (
      <ThemeWrapper>
        <Helmet
          title={info.title}
          meta={[
            { name: 'description', content: info.description },
            { name: 'keywords', content: info.keywords },
            { name: 'bs:githead', content: process.env.GATSBY_GIT_HEAD },
            { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=0' }
          ]}
        />
        <LayoutMain {...props}>{children}</LayoutMain>
      </ThemeWrapper>
    )}
  />
);

export default IndexLayout;
